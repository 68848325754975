import telegrafRegularWoff from './Telegraf/TelegrafRegular400.woff'
import telegrafRegularWoff2 from './Telegraf/TelegrafRegular400.woff2'
import poppinsBlackWoff from './Poppins/Poppins-Black.woff'
import poppinsBlackWoff2 from './Poppins/Poppins-Black.woff2'
import poppinsBoldWoff from './Poppins/Poppins-Bold.woff'
import poppinsBoldWoff2 from './Poppins/Poppins-Bold.woff2'
import poppinsExtraLightWoff from './Poppins/Poppins-ExtraLight.woff'
import poppinsExtraLightWoff2 from './Poppins/Poppins-ExtraLight.woff2'
import poppinsLightWoff from './Poppins/Poppins-Light.woff'
import poppinsLightWoff2 from './Poppins/Poppins-Light.woff2'
import poppinsMediumWoff from './Poppins/Poppins-Medium.woff'
import poppinsMediumWoff2 from './Poppins/Poppins-Medium.woff2'
import poppinsRegularWoff from './Poppins/Poppins-Regular.woff'
import poppinsRegularWoff2 from './Poppins/Poppins-Regular.woff2'
import poppinsSemiBoldWoff from './Poppins/Poppins-SemiBold.woff'
import poppinsSemiBoldWoff2 from './Poppins/Poppins-SemiBold.woff2'
import poppinsThinWoff from './Poppins/Poppins-Thin.woff'
import poppinsThinWoff2 from './Poppins/Poppins-Thin.woff2'
import merriweatherRegularWoff2 from './Merriweather/Merriweather-Regular.woff2'
import merriweatherRegularExtWoff2 from './Merriweather/Merriweather-Regular-ext.woff2'
import merriweatherBoldWoff2 from './Merriweather/Merriweather-Bold.woff2'
import merriweatherBoldExtWoff2 from './Merriweather/Merriweather-Bold-ext.woff2'
import silkaSemiBoldWoff from './Silka/Silka-SemiBold.woff'
import silkaSemiBoldWoff2 from './Silka/Silka-SemiBold.woff2'
import silkaSemiBoldItalicWoff from './Silka/Silka-SemiBoldItalic.woff'
import silkaSemiBoldItalicWoff2 from './Silka/Silka-SemiBoldItalic.woff2'

const localFonts = `
  /* latin-ext */
  @font-face {
    font-family: 'Merriweather';
    src: local('Merriweather Regular Ext'), local('Merriweather-Regular-Ext'),
      url(${merriweatherRegularExtWoff2}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* latin */
  @font-face {
    font-family: 'Merriweather';
    src: local('Merriweather Regular'), local('Merriweather-Regular'),
      url(${merriweatherRegularWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Merriweather';
    src: local('Merriweather Bold Ext'), local('Merriweather-Bold-Ext'),
      url(${merriweatherBoldExtWoff2}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  /* latin */
  @font-face {
    font-family: 'Merriweather';
    src: local('Merriweather Bold'), local('Merriweather-Bold'),
      url(${merriweatherBoldWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Telegraf';
    src: local('Telegraf Regular'), local('Telegraf-Regular'),
      url(${telegrafRegularWoff2}) format('woff2'),
      url(${telegrafRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Black'), local('Poppins-Black'),
      url(${poppinsBlackWoff2}) format('woff2'),
      url(${poppinsBlackWoff}) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Bold'), local('Poppins-Bold'),
      url(${poppinsBoldWoff2}) format('woff2'),
      url(${poppinsBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Extra Light'), local('Poppins-ExtraLight'),
      url(${poppinsExtraLightWoff2}) format('woff2'),
      url(${poppinsExtraLightWoff}) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Light'), local('Poppins-Light'),
      url(${poppinsLightWoff2}) format('woff2'),
      url(${poppinsLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Medium'), local('Poppins-Medium'),
      url(${poppinsMediumWoff2}) format('woff2'),
      url(${poppinsMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Regular'), local('Poppins-Regular'),
      url(${poppinsRegularWoff2}) format('woff2'),
      url(${poppinsRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
      url(${poppinsSemiBoldWoff2}) format('woff2'),
      url(${poppinsSemiBoldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Thin'), local('Poppins-Thin'),
      url(${poppinsThinWoff2}) format('woff2'),
      url(${poppinsThinWoff}) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Silka';
    src: url('${silkaSemiBoldWoff2}') format('woff2'),
      url('${silkaSemiBoldWoff}') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Silka';
    src: url('${silkaSemiBoldItalicWoff2}') format('woff2'),
      url('${silkaSemiBoldItalicWoff}') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }
`

export default localFonts
