export const IS_COURSE_SOLD_OUT = false

export const SCROLL_TO_NAME = {
  COURSES: 'courses',
  SUBSCRIBE: 'subscribe',
}

export const FORM_NAME = {
  APPLICATION: 'application',
  CONTACT: 'contact',
  SYLLABUS: 'syllabus',
}

export const EMAIL = { SUDO_ACADEMY: 'info@sudoacademy.io' }

export const URL = {
  GYMBEAM: 'https://gymbeam.sk/',
  SUDOLABS: 'https://sudolabs.io/',
  GROWNAPPS: 'https://grownapps.io/sk/',
  NORDLICHT: 'https://nordlicht-it.com/',
  PROMISEO: 'https://www.promiseo.sk/',
  IT_IMPULSE: 'https://it-impulse.sk/',
  HOTOVO: 'https://hotovo.com/',
  UNIQUE_PEOPLE: 'https://uniquepeople.sk/',
  GOHEALTH: 'https://www.gohealth.sk/',
  SENACOR: 'https://senacor.com/',
  GOODREQUEST: 'https://www.goodrequest.com/',
  WEBSUPPORT: 'https://www.websupport.sk/',
  VACUUMLABS: 'https://vacuumlabs.com/',
  INSTEA: 'https://instea.sk',
}

export const AOS_ANIMATION = { FADE_UP: { 'data-aos': 'fade-up' } }

export const DOCUMENT_URL = {
  PRIVACY_POLICY: '/privacy_policy.pdf',
  TRADING_CONDITIONS: '/trading_conditions.pdf',
  SYLLABUS_JAVASCRIPT: '/sylabus_javascript_developer.pdf',
  SYLLABUS_PRODUCT_MANAGER: '/sylabus_product_manager.pdf',
  COOKIES_POLICY: 'cookies_policy.pdf',
}

export const ROUTES = {
  BLOG: '/blog',
  REVIEWS: '/reviews',
  HOME: '/',
}

export const SUBSCRIBE_FORM_NAME_INPUT_ID = 'subscribe-form-name-input'

export const VIDEO_URL = {
  HOME: 'https://youtu.be/vdOF6zicGxI',
  REVIEWS: 'https://youtu.be/uSwzDNobBSc',
}
