import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { useLocation } from '@reach/router'
import { COLOR, SPACE } from 'Theme'
import { ROUTES } from 'constants/constants'

const BANNER_HEIGHT = rem(50)
const BANNER_ENABLED_ROUTES = [
  ROUTES.BLOG,
  ROUTES.PRODUCT_MANAGEMENT,
  ROUTES.JAVASCRIPT,
  ROUTES.HOME,
]

const StyledBanner = styled('div')`
  z-index: 1;
  position: sticky;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: ${BANNER_HEIGHT};
  padding: 0 ${SPACE.M};

  line-height: 1.3;
  text-align: center;

  background-color: ${({ $backgroundColor }) => $backgroundColor};
`

const Banner = ({ backgroundColor, children }) => {
  const { pathname } = useLocation()

  const isEnabled = !BANNER_ENABLED_ROUTES.some((route) =>
    pathname.startsWith(route)
  )

  return isEnabled ? (
    <StyledBanner $backgroundColor={backgroundColor}>
      <div>{children}</div>
    </StyledBanner>
  ) : null
}

Banner.defaultProps = {
  backgroundColor: COLOR.PRIMARY_RED,
}

Banner.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Banner
