import React from 'react'
import styled from 'styled-components'
import { SPACE } from 'Theme'
import Facebook from 'images/svg/facebook.inline.svg'
import Instagram from 'images/svg/instagram.inline.svg'
import Linkedin from 'images/svg/linkedin.inline.svg'

const SocialLink = styled('a')`
  display: inline;
  padding: ${SPACE.S} 0;
`

SocialLink.defaultProps = {
  target: '_blank',
  rel: 'noreferrer noopener',
}

const Socials = () => (
  <>
    <SocialLink
      id="footer-sudoacademy-facebook"
      href="https://www.facebook.com/sudoacademy"
    >
      <Facebook />
    </SocialLink>
    <SocialLink
      id="footer-sudoacademy-instagram"
      href="https://www.instagram.com/sudo_academy/"
    >
      <Instagram />
    </SocialLink>
    <SocialLink
      id="footer-sudoacademy-linkedin"
      href="https://www.linkedin.com/company/sudo-academy/"
    >
      <Linkedin />
    </SocialLink>
  </>
)

export default Socials
