import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { mq, SPACE, COLOR, FONT_SIZE, FONT_WEIGHT } from 'Theme'
import { DOCUMENT_URL } from 'constants/constants'
import { Text } from 'components/Typography'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import Transition, { TRANSITION_STATE } from 'components/Transition'

const DURATION = 300
const BUTTON_HEIGHT = rem(45)

const DEFAULT_STATES = {
  [TRANSITION_STATE.EXITED]: 0,
  [TRANSITION_STATE.EXITING]: 0,
  [TRANSITION_STATE.ENTERED]: 1,
  [TRANSITION_STATE.ENTERING]: 1,
}

const Container = styled('div')`
  z-index: 1;
  position: fixed;
  width: 30rem;
  display: grid;
  gap: 1rem;
  justify-items: center;
  border: 1px solid ${COLOR.WHITE_50};
  background-color: ${COLOR.BLACK};
  transition: opacity ${DURATION}ms ease-in-out;
  ${({ transitionState }) => `opacity: ${DEFAULT_STATES[transitionState]};`}
  padding: ${SPACE.L} ${SPACE.M};
  ${mq.to.M`
    width: 100vw;
    padding: ${SPACE.L};
    border: none;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  `}
  ${mq.from.M`
    bottom: ${SPACE.L};
    right: ${SPACE.L};
  `}
`

const UnstyledButton = styled('button')`
  background: none;
  font-size: ${FONT_SIZE.S};
  color: ${COLOR.PRIMARY_RED};
  cursor: pointer;
  display: inline-block;
  padding: 0.5em 0;
  line-height: 1;
`

const ConsentBar = ({ onAccept, onClose, isVisible }) => (
  <Transition in={isVisible} timeout={DURATION} mountOnEnter unmountOnExit>
    {(state) => (
      <Container transitionState={state}>
        <Box textAlign="center">
          <Text
            as="p"
            fontSize={FONT_SIZE.S}
            fontWeight={FONT_WEIGHT.SEMI_BOLD}
            mb={SPACE.S}
          >
            Táto webová stránka používa súbory cookies
          </Text>
          <Text as="p" fontSize={FONT_SIZE.S}>
            na zaistenie správneho fungovania stránky, zlepšenie vášho komfortu
            a pre umožnenie efektívneho marketingu. Pre viac informácií o
            cookies kliknite{' '}
            <Text
              id="consent-bar-privacy-policy"
              as="a"
              target="_blank"
              href={DOCUMENT_URL.COOKIES_POLICY}
              color={COLOR.PRIMARY_RED}
            >
              TU
            </Text>
            .
          </Text>
        </Box>
        <Button
          id="consent-bar-accept"
          onClick={onAccept}
          height={BUTTON_HEIGHT}
        >
          Súhlasím s používaním cookies
        </Button>
        <UnstyledButton id="consent-bar-accept" onClick={onClose}>
          Zamietnuť nepovinné cookies
        </UnstyledButton>
      </Container>
    )}
  </Transition>
)

ConsentBar.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
}

export default ConsentBar
