import styled from 'styled-components'
import { mq } from 'Theme'

export const MobileOnly = styled('div')`
  display: none;
  ${mq.to.M`
    display: contents;
  `}
`

export const DesktopOnly = styled('div')`
  display: contents;
  ${mq.to.M`
    display: none;
  `}
`
