import 'sanitize.css'
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useLocation } from '@reach/router'
import { GlobalStyles, theme } from 'Theme'
import FeedbackMessageProvider from 'utils/FeedbackMessageProvider'
import localFonts from 'fonts'
import { FORM_NAME } from 'constants/constants'
import ConsentBar from 'components/ConsentBar'
import ReactGA4 from 'react-ga4'

const COOKIE_CONSENT_NAME = 'CookieConsent'

// see `cookieName` inside `gatsby-config.js` under `gatsby-plugin-gdpr-cookies` key
const COOKIE_PIXEL_NAME = 'gatsby-gdpr-facebook-pixel'
const COOKIE_HOTJAR_NAME = 'gatsby-gdpr-hotjar'

const NetlifyDummyForms = () => {
  const netlifyFormProps = {
    netlify: 'true',
    method: 'post',
    'data-netlify': 'true',
    'data-netlify-honeypot': 'bot-field',
    hidden: true,
  }

  return (
    <>
      <form name={FORM_NAME.CONTACT} {...netlifyFormProps}>
        <input type="text" name="name" />
        <input type="email" name="email" />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <select name="type" />
        <input type="hidden" name="origin" />
      </form>
      <form name={FORM_NAME.APPLICATION} {...netlifyFormProps}>
        <input type="text" name="name" />
        <input type="email" name="email" />
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <select name="knowledge" />
        <input type="text" name="profession" />
        <input type="hidden" name="type" />
      </form>
      <form name={FORM_NAME.SYLLABUS} {...netlifyFormProps}>
        <input type="text" name="name" />
        <input type="email" name="email" />
        <input type="hidden" name="type" />
      </form>
    </>
  )
}

const App = ({ children }) => {
  const [isConsentBarVisible, setIsConsentBarVisible] = React.useState(false)
  const [hasCookieConsent, setHasCookieConsent] = React.useState(false) // eslint-disable-line no-unused-vars
  const location = useLocation()

  React.useEffect(() => {
    const getConsent = async () => {
      const cookieConsent = Cookies.get(COOKIE_CONSENT_NAME)

      if (cookieConsent === 'true') {
        setHasCookieConsent(true)
        Cookies.set(COOKIE_PIXEL_NAME, 'true')
        Cookies.set(COOKIE_HOTJAR_NAME, 'true')
        initializeAndTrack(location)
      }
      setIsConsentBarVisible(cookieConsent === undefined)
    }
    getConsent()
  }, [isConsentBarVisible, location])

  const handleAcceptConsent = async () => {
    Cookies.set(COOKIE_CONSENT_NAME, true)
    ReactGA4.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
    })
    setIsConsentBarVisible(false)
  }

  const handleCloseConsent = () => {
    Cookies.set(COOKIE_CONSENT_NAME, false)
    ReactGA4.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
    })
    setIsConsentBarVisible(false)
  }

  React.useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty(
        '--sa-100vh',
        `${window.innerHeight}px`
      )
    }

    window.addEventListener('resize', handleResize)
    document.documentElement.style.setProperty(
      '--sa-100vh',
      `${window.innerHeight}px`
    )

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <FeedbackMessageProvider>
        <Helmet defer={false}>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <style>{localFonts}</style>
          <script>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_ID}');
            `}
          </script>
          <script
            src={`https://www.googleoptimize.com/optimize.js?id=${process.env.GATSBY_OPTIMIZE_ID}`}
          />
        </Helmet>
        <noscript>
          <iframe
            title="googletagmanager-noscript"
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTM_ID}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        <NetlifyDummyForms />
        <GlobalStyles />
        <ConsentBar
          onClose={handleCloseConsent}
          onAccept={handleAcceptConsent}
          isVisible={isConsentBarVisible}
        />
        {children}
      </FeedbackMessageProvider>
    </ThemeProvider>
  )
}

App.propTypes = {
  children: PropTypes.node.isRequired,
}

export default App
