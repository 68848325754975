import styled from 'styled-components'
import { rem, transparentize } from 'polished'
import { space } from 'styled-system'
import { FONT_SIZE, FONT_WEIGHT, SPACE, COLOR } from 'Theme'

const BUTTON_WIDTH = rem(175)
export const BUTTON_HEIGHT = rem(48)

const Button = styled('button')`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none;
  height: ${({ height }) => height};
  min-width: ${BUTTON_WIDTH};
  font-size: ${FONT_SIZE.S};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  color: ${COLOR.BLACK};
  background-color: ${COLOR.PRIMARY_RED};
  appearance: none;
  ${({ isFullWidth }) => isFullWidth && `width: 100%`};

  ${({ variant }) => {
    switch (variant) {
      case 'secondary':
        return `
        color: ${COLOR.PRIMARY_RED};
        background-color: ${transparentize(0.8, COLOR.PRIMARY_RED)};

        &:hover {
          background-color: ${transparentize(0.6, COLOR.PRIMARY_RED)}
        }
      `
      case 'supplementary':
        return `
        border: 1px solid ${COLOR.PRIMARY_RED};
        color: ${COLOR.PRIMARY_RED};
        background-color: transparent;

        &:hover {
          background-color: ${transparentize(0.8, COLOR.PRIMARY_RED)};
        }
      `
      case 'banner':
        return `
        color: ${COLOR.WHITE};
        background-color: ${COLOR.BLACK};

        &:hover {
          background-color: #1A1A1A;
        }
      `
      default:
        return `
        &:hover {
          background-color: ${COLOR.PRIMARY_RED_20};
        }

        &:disabled {
          background-color: ${COLOR.PRIMARY_RED_40};
        }
      `
    }
  }}

  &:disabled {
    cursor: not-allowed;
  }

  img,
  svg {
    margin-right: ${SPACE.S};
  }

  ${space}
`

Button.defaultProps = {
  type: 'button',
  height: BUTTON_HEIGHT,
  px: SPACE.M,
}

export default Button
