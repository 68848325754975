import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR, FONT_SIZE, FONT_WEIGHT, SPACE, mq } from 'Theme'

const StyledTitle = styled('h3')`
  font-size: ${FONT_SIZE.S};
  text-transform: uppercase;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  color: ${COLOR.WHITE_50};
  margin-bottom: ${SPACE.S};
  ${mq.from.M`
    display: none;
  `}
`

const Title = ({ children }) => <StyledTitle>{children}</StyledTitle>

Title.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Title
