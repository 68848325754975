import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { COLOR, FONT_SIZE, FONT_WEIGHT, SPACE, mq } from 'Theme'
import { Box } from 'components/Layout'
import Logo from 'components/Logo'
import { Text } from 'components/Typography'
import { getMaxWidthStyle } from 'components/mixins'
import Banner from 'routes/components/Banner'
import App from 'App'
import { MainMenu } from 'components/MainMenu'
import Footer from './Footer'
import Socials from './Socials'

const ANIMATION_DURATION = 400
const HEADER_CHILDREN_WIDTH = 218

const Header = styled('nav')`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;

  display: flex;
  justify-content: space-between;

  padding-top: ${SPACE.L};
  // This makes nav be in line with menu modal
  padding-left: ${SPACE.L} !important;
  padding-right: ${SPACE.L} !important;

  ${mq.from.L`
    align-items: center;
  `}
`

const Content = styled('div')`
  z-index: 0;
  position: relative;
`

const StyledLogo = styled(Logo)`
  width: ${HEADER_CHILDREN_WIDTH}px;

  ${mq.to.L`
    width: auto;
  `}
`

const StyledSocials = styled('div')`
  display: flex;
  justify-content: flex-end;
  grid-gap: ${SPACE.M};

  width: ${HEADER_CHILDREN_WIDTH}px;

  ${mq.to.L`
    display: none;
  `}
`

const GenericPage = ({
  children,
  maxContentWidth,
  onBannerClick,
  ...props
}) => {
  React.useEffect(() => {
    AOS.init({
      duration: ANIMATION_DURATION,
      once: true,
    })
  }, [])

  return (
    <App>
      <Banner>
        <Text as="p" fontSize={FONT_SIZE.S}>
          Z dôvodu pandémie začíname v online forme.{' '}
          <button
            type="button"
            onClick={() => onBannerClick?.()}
            css={`
              ${!onBannerClick ? `cursor: text;` : ''}
              font-size: inherit;
              font-weight: ${FONT_WEIGHT.MEDIUM};
              text-decoration: underline;
              color: ${COLOR.WHITE};
            `}
          >
            Bežia posledné týždne na prihlásenie!
          </button>
        </Text>
      </Banner>
      <Box {...props}>
        <Content css={getMaxWidthStyle(maxContentWidth)}>
          <Header css={getMaxWidthStyle(maxContentWidth)}>
            <StyledLogo />
            <MainMenu />
            <StyledSocials>
              <Socials />
            </StyledSocials>
          </Header>
          {children}
        </Content>
        <Box css={getMaxWidthStyle()} mt={{ S: SPACE.L, M: SPACE.XL }}>
          <Footer mb={SPACE.XL} />
        </Box>
      </Box>
    </App>
  )
}

GenericPage.propTypes = {
  children: PropTypes.node.isRequired,
  maxContentWidth: PropTypes.string,
  onBannerClick: PropTypes.func,
}

export default GenericPage
