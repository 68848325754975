import React from 'react'
import styled from 'styled-components'
import { mq, SPACE, COLOR, FONT_SIZE, MAX_CONTENT_WIDTH } from 'Theme'
import { AOS_ANIMATION, EMAIL, ROUTES } from 'constants/constants'
import { Box } from 'components/Layout'
import { Link } from 'gatsby'
import Logo from 'components/Logo'
import Socials from './Socials'

const footerMQ = mq.to.M

const StyledFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  max-width: ${MAX_CONTENT_WIDTH.DEFAULT};
  font-size: ${FONT_SIZE.S};
  margin: 0 auto;
  padding: ${SPACE.M} 0;
  ${footerMQ`
    flex-direction: column;
    align-items: center;
    padding: ${SPACE.XL} 0 ${SPACE.M};
  `}
`

const MenuColumn = styled('div')`
  display: flex;
  flex-direction: column;
  ${footerMQ`
    align-items: center;
    padding-bottom: ${SPACE.L};
  `}
`

const MenuColumnMobile = styled(MenuColumn)`
  display: none;
  ${footerMQ`
    display: flex
  `}
`

const MenuTitle = styled('h4')`
  margin-bottom: ${SPACE.M};
  text-transform: uppercase;
  color: ${COLOR.WHITE_50};
  font-weight: 500;
`

const MenuItem = styled('p')`
  display: block;
  width: fit-content;
  padding-bottom: ${SPACE.S};
`

const StyledLink = styled(Link)`
  color: ${COLOR.WHITE};
  text-decoration: none;

  &:hover {
    color: ${COLOR.PRIMARY_RED};
  }
`

const StyledMenuItem = styled(MenuItem)`
  color: ${COLOR.WHITE};
  text-decoration: none;
`

const SocialWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${SPACE.M};

  width: max-content;

  ${mq.from.M`
    display: none;
  `}
`

const SocialDesktop = styled(SocialWrapper)`
  display: grid;
  margin-top: ${SPACE.M};

  ${mq.to.M`
    display: none;
  `}
`

const Footer = ({ ...props }) => (
  <Box overflowY={{ S: 'hidden', M: 'unset' }} {...props}>
    <StyledFooter {...AOS_ANIMATION.FADE_UP}>
      <MenuColumn>
        <Logo />
        <SocialDesktop>
          <Socials />
        </SocialDesktop>
      </MenuColumn>
      <MenuColumn>
        <MenuTitle>Rýchla navigácia</MenuTitle>
        <MenuItem>
          <StyledLink to={ROUTES.HOME}>Product Manager kurz</StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink to={ROUTES.REVIEWS}>Povedali o nás</StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink to={ROUTES.BLOG}>Blog</StyledLink>
        </MenuItem>
      </MenuColumn>
      <MenuColumn>
        <MenuTitle>Adresa</MenuTitle>
        <MenuItem>DUETT Business Residence</MenuItem>
        <MenuItem>Námestie osloboditeľov 3/A</MenuItem>
        <MenuItem>040 01 Košice, Slovakia</MenuItem>
      </MenuColumn>
      <MenuColumn>
        <MenuTitle>Kontakt</MenuTitle>

        <StyledMenuItem
          id="footer-email"
          as="a"
          href={`mailto:${EMAIL.SUDO_ACADEMY}`}
        >
          {EMAIL.SUDO_ACADEMY}
        </StyledMenuItem>
      </MenuColumn>
      <MenuColumnMobile>
        <MenuTitle>Socials</MenuTitle>
        <SocialWrapper>
          <Socials />
        </SocialWrapper>
      </MenuColumnMobile>
    </StyledFooter>
  </Box>
)

export default Footer
