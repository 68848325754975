import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { COLOR, FONT_SIZE, SPACE, mq } from 'Theme'
import { EMAIL, ROUTES } from 'constants/constants'
import xMarkIcon from 'images/svg/close.svg'
import hamburgerIcon from 'images/svg/hamburger.svg'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import { DesktopOnly, MobileOnly } from 'components/ResponsiveDisplay'
import { Flex } from 'components/Layout'
import Logo from 'components/Logo'
import Title from 'components/MainMenu/Title'
import Item from 'components/MainMenu/Item'
import Socials from 'components/Socials'

const ItemsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${mq.to.M`
    flex-direction: column;
    text-align: left;
    padding-top: ${SPACE.XL};
  `}
`

const MobileMenu = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: ${SPACE.L};

  padding: ${SPACE.L};
  overflow-y: auto;

  background-color: ${COLOR.BLACK};
  box-shadow: 0px 4px 10px rgba(23, 35, 49, 0.06),
    0px 16px 40px rgba(23, 35, 49, 0.08);

  transition: opacity 0.2s ease-out;

  ${({ $isVisible }) =>
    !$isVisible &&
    `
      pointer-events: none;
      user-select: none;

      opacity: 0;
    `}
`

const MenuButton = styled('button').attrs({
  type: 'button',
})`
  position: relative;
  top: -${SPACE.S};
  right: -${SPACE.S};

  padding: ${SPACE.S};
  align-self: flex-end;

  img {
    height: ${rem(20)};
    width: ${rem(20)};
  }
`

const MailLink = styled('a')`
  text-decoration: none;
  font-size: ${FONT_SIZE.XXL};
  color: ${COLOR.WHITE};
`

const StyledSocials = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${SPACE.M};
`

const MainMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const mobileMenuRef = React.useRef(null)

  React.useEffect(() => {
    if (mobileMenuRef.current) {
      if (isMenuOpen) {
        disableBodyScroll(mobileMenuRef.current)
      } else {
        enableBodyScroll(mobileMenuRef.current)
      }
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isMenuOpen])

  return (
    <>
      <MobileOnly>
        <MenuButton
          onClick={() => {
            setIsMenuOpen(true)
          }}
        >
          <img src={hamburgerIcon} alt="Open Menu" />
        </MenuButton>
        <MobileMenu $isVisible={isMenuOpen} ref={mobileMenuRef}>
          <Flex justifyContent="space-between" width="100%">
            <Logo />
            <MenuButton
              onClick={() => {
                setIsMenuOpen(false)
              }}
            >
              <img src={xMarkIcon} alt="Close Menu" />
            </MenuButton>
          </Flex>
          <ItemsWrapper>
            <Title>Menu</Title>
            <Item
              id="main-menu-home"
              to={ROUTES.HOME}
              onClick={() => {
                setIsMenuOpen(false)
              }}
            >
              Kurz Product Manager
            </Item>
            <Item
              id="main-menu-reviews"
              to={ROUTES.REVIEWS}
              onClick={() => {
                setIsMenuOpen(false)
              }}
            >
              Povedali o nás
            </Item>
            <Item
              id="main-menu-blog"
              to={ROUTES.BLOG}
              onClick={() => {
                setIsMenuOpen(false)
              }}
            >
              Blog
            </Item>
          </ItemsWrapper>
          <div>
            <Title>Kontakt</Title>
            <MailLink
              id="main-menu-email"
              href={`mailto:${EMAIL.SUDO_ACADEMY}`}
            >
              {EMAIL.SUDO_ACADEMY}
            </MailLink>
          </div>

          <div>
            <Title>Sleduj nás</Title>
            <StyledSocials>
              <Socials />
            </StyledSocials>
          </div>
        </MobileMenu>
      </MobileOnly>

      <DesktopOnly>
        <ItemsWrapper>
          <Item id="main-menu-home" to={ROUTES.HOME}>
            Kurz Product Manager
          </Item>
          <Item id="main-menu-reviews" to={ROUTES.REVIEWS}>
            Povedali o nás
          </Item>
          <Item id="main-menu-blog" to={ROUTES.BLOG}>
            Blog
          </Item>
        </ItemsWrapper>
      </DesktopOnly>
    </>
  )
}

export default MainMenu
