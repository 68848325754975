import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { COLOR, FONT_SIZE } from 'Theme'
import { ROUTES } from 'constants/constants'
import { Text } from 'components/Typography'

const StyledText = styled(Text)`
  font-family: Telegraf, sans-serif;
  font-size: ${FONT_SIZE.XXXL};
  line-height: ${FONT_SIZE.XXXL};
  text-decoration: none;

  color: ${COLOR.WHITE};

  &:first-letter {
    color: ${COLOR.PRIMARY_RED};
  }
`

const Logo = (props) => (
  <StyledText as={Link} to={ROUTES.HOME} {...props}>
    sudo academy
  </StyledText>
)

export default Logo
