import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Match } from '@reach/router'
import { Link } from 'gatsby'
import { COLOR, FONT_SIZE, FONT_WEIGHT, SPACE, mq } from 'Theme'

const StyledLink = styled(Link)`
  padding: ${SPACE.XS} 0;
  margin: ${SPACE.XS} 0;

  font-size: ${FONT_SIZE.XXL};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  white-space: nowrap;
  text-decoration: none;

  color: ${COLOR.WHITE};

  &:hover {
    text-decoration: underline;
  }

  ${mq.from.M`
    padding: 0 ${SPACE.M};
    margin: 0 ${SPACE.S};
    text-transform: uppercase;
    font-size: ${FONT_SIZE.L};
  `}

  ${({ $isActive }) => $isActive && `color: ${COLOR.PRIMARY_RED};`}
`

const Item = ({ to, children, ...props }) => (
  <Match path={to}>
    {({ match }) => (
      <StyledLink $isActive={match} to={to} {...props}>
        {children}
      </StyledLink>
    )}
  </Match>
)

Item.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Item
